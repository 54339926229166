<template>
	<div class="decision-answers">

		<main-title v-if="selected_question && !correct_answer" :text="select_option_text" :closable="false" />

		<div v-if="selected_question && !correct_answer" class="answers">
			<div v-for="answer in selected_question.answer"
				:key="answer.id"
				class="answer"
				:class="{ checked: checked_answer && checked_answer.id === answer.id, unchecked: checked_answer && checked_answer.id !== answer.id }"
				v-hammer:tap="() => toggle_check_answer(answer)"
			>
				<div class="text"><ui-text :text="answer.answer" /></div>
				<div class="checkbox">
					<ui-icon v-if="checked_answer && checked_answer.id === answer.id" class="check-circle" key="a" name="check-circle" :size="30" />
					<ui-icon v-else class="circle" key="b" name="circle" :size="26" />
				</div>
			</div>
		</div>

		<div v-if="selected_question && !correct_answer && game.room.state !== 'paused'" class="buttons">
			<ui-button v-hammer:tap="() => send_answer(checked_answer)" :loading="loading" :inactive="!checked_answer"><span>Submit</span></ui-button>
		</div>

		<div v-if="game.room.state === 'paused' && !correct_answer" class="paused-overlay">
			<p>The game is paused.</p>
			<p>Awaiting for game resume...</p>
		</div>

		<transition name="outcome">
			<div v-if="(selected_answer || correct_answer)" class="outcome">
				<div v-if="selected_question.type === 'Solo' || (correct_answer && correct_answer.result_answer && correct_answer.result_answer.answer)" class="outcome-main" v-scrollbar>
					<div>
						<div class="box">
							<br>
							<div class="box-title">
								<span v-if="selected_question.type === 'Solo'">Your choice</span>
								<span v-if="selected_question.type === 'Majority'">Majority's choice</span>
								<span v-if="selected_question.type === 'Decider'">Decider's choice</span>

								<div v-if="majority_users" class="users">
									<div v-for="(c, index) in majority_users" :key="index" class="user" :class="c">
										<ui-icon class="user-empty" name="progress/user-empty" size="24" />
										<ui-icon class="user-full" name="progress/user-full" size="24" />
										<ui-icon class="user-crown" name="progress/user-crown" size="24" />
									</div>
								</div>
							</div>
							<div class="box-content">
								<div class="text">
									<ui-text v-if="selected_question.type === 'Solo'" :text="correct_answer.answer" />
									<ui-text v-else :text="correct_answer.result_answer.answer" />
								</div>
								<ul v-if="correct_answer && correct_answer.result_answer && correct_answer.answers" class="result-stats">
									<li>Has been chosen by {{ correct_answer.answers.filter((a) => { return parseInt(a) === parseInt(correct_answer.result) }).length }} of {{ correct_answer.answers.length }} deciders</li>
									<li v-if="progress.selected_answers.includes(parseInt(correct_answer.result))" class="own">This was also your choice</li>
									<li v-else class="own">This was not your choice</li>
									<li v-if="!correct_answer.majority" class="gold">This was the tiebreaker's choice</li>
								</ul>
							</div>
						</div>

						<div v-if="correct_answer.feedback || (correct_answer.result_answer && correct_answer.result_answer.feedback)" class="box">
							<div class="box-title">Outcome</div>
							<div class="box-content">
								<div class="text">
									<ui-text v-if="selected_question.type === 'Solo'" :text="correct_answer.feedback" />
									<ui-text v-else :text="correct_answer.result_answer.feedback" />
								</div>
							</div>
						</div>

						<div class="box losing">
							<div class="box-title">Not chosen</div>
							<div class="box-content">
								<div v-for="answer in wrong_answers" :key="answer.id" class="option-answer">
									<div class="text">
										<ui-text :text="answer.answer" />
									</div>
									<ul class="result-stats">
										<li v-if="correct_answer && correct_answer.answers">
											Has been chosen by {{ correct_answer.answers.filter((a) => { return parseInt(a) === parseInt(answer.id) }).length }} of {{ correct_answer.answers.length }} deciders
											<span class="users">
												<span v-for="(u, index) in majority_users" :key="index" class="user" :class="{ 'c-full': index >= correct_answer.answers.length - correct_answer.answers.filter((a) => { return parseInt(a) === parseInt(answer.id) }).length }">
													<ui-icon class="user-empty" name="progress/user-empty" size="24" />
													<ui-icon class="user-full" name="progress/user-full" size="24" />
													<ui-icon class="user-crown" name="progress/user-crown" size="24" />
												</span>
											</span>
										</li>
										<li v-if="progress.selected_answers.includes(parseInt(answer.id))" class="own">This was your choice</li>
									</ul>
								</div>
							</div>
						</div>

					</div>
				</div>
				<div v-else-if="selected_question.type === 'Algorithmic' && correct_answer" class="outcome-main algorithmic" v-scrollbar>
					<div>
						<div class="box">
							<br>
							<div class="box-title">
								<span>You decided on</span>
							</div>
							<div class="box-content">
								<div class="units">
									{{ algorithmic_selected_units }} units
									<span class="small">of {{ algorithmic_total_units }}</span>
								</div>
								<ui-text class="description" :text="'This represents ' + Math.round(algorithmic_selected_units * 100 / algorithmic_total_units) + '% of your yearly available units.'"></ui-text>
							</div>
							
						</div>
					</div>
				</div>
				<div v-else class="waiting">
					<ui-text :text="'# Awaiting all votes...\r\rThe decision will be updated once the time has run out.'" />
				</div>
			</div>
		</transition>

	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Apollo from '@/apollo/apollo-client'
import MainTitle from '@/components/game/main-title'
import UiButton from '@/components/ui/ui-button'
import UiText from '@/components/ui/ui-text'

export default {
	name: 'decision-answers',
	data() {
		return {
			loading: false,
			checked_answer: null
		}
	},
	props: {
		selected_question: { default: null },
		selected_answer: { default: null },
		correct_answer: { default: null }
	},
	computed: {
		...mapGetters([ 'game', 'progress', 'logged_user', 'current_question', 'selected_question_config' ]),
		select_option_text() {
			let str = "Select an option"
			if (this.selected_question && this.selected_question.type === 'Majority') str += " • Majority vote"
			if (this.selected_question && this.selected_question.type === 'Decider') str += " • Decider vote"
			return str
		},
		majority_users() {
			let users = []
			if (this.correct_answer && this.correct_answer.answers) {
				this.correct_answer.answers.forEach((id) => {
					if (id === this.correct_answer.result) users.push('c-full')
					else users.push('a-empty')
				})
				if (!this.correct_answer.majority) {
					let index = users.lastIndexOf('c-full')
					if (index > -1) users[index] = 'b-crown'
				}
				return users.sort()
			}
			return users
		},
		wrong_answers() {
			if (this.correct_answer) {
				const id = this.correct_answer.result ? parseInt(this.correct_answer.result) : parseInt(this.correct_answer.id)
				return this.selected_question.answer.filter((a) => parseInt(a.id) !== id)
			}
			return []
		},
		algorithmic_selected_units() {
			if (this.selected_question && this.selected_question.type === 'Algorithmic' && this.selected_question.majority_answer) {
				const a = this.selected_question.majority_answer.unit_details.find(u => parseInt(u.role_id) === parseInt(this.logged_user.role.id))
				if (a) return a.unit
			}
			return null
		},
		algorithmic_total_units() {
			if (this.selected_question && this.selected_question.type === 'Algorithmic') {
				if (this.selected_question_config && this.selected_question_config.units) {
					return this.selected_question_config.units.answers[this.selected_question_config.units.answers.length - 1].value
				}
				// const answer = this.selected_question.majority_answer ? this.selected_question.majority_answer : this.selected_question.awaiting_algorithmic_answer
				// if (answer) return answer.unit_total
			}
			return null
		}
	},
	// watch: {
	// 	selected_question(question) {
	// 		if (question) this.get_algorithmic_result(question)
	// 	}
	// },
	methods: {
		show_infobox(data) {
			if (data.slug) {
				this.$store.dispatch('add_info_box', { slug: data.slug })
			}
			else if (data.question) {
				if (data.question.type === 'Majority') {
					let params = { TIE_BREAKER_ROLE_NAME: '???' }
					let tie_breaker = this.game.roles.find((r) => { return parseInt(r.id) === data.question.tiebreaker })
					if (tie_breaker && tie_breaker.name) params = { TIE_BREAKER_ROLE_NAME: tie_breaker.name }
					this.$store.dispatch('add_info_box', { slug: 'majority_intro', params })
				}
				if (data.question.type === 'Decider') {
					const decider = data.question.deciders.find((d) => { return d === parseInt(this.logged_user.role.id) })
					if (decider) this.$store.dispatch('add_info_box', { slug: 'decider' })

					const contributor = data.question.contributors.find((d) => { return d === parseInt(this.logged_user.role.id) })
					if (contributor) this.$store.dispatch('add_info_box', { slug: 'contributor' })
				}
			}
		},
		toggle_check_answer(answer) {
			if (this.checked_answer && this.checked_answer.id === answer.id)
				this.checked_answer = null
			else
				this.checked_answer = answer
		},
		// async get_algorithmic_result(question) {
		// 	if (this.selected_question.type === 'Algorithmic' && !question.awaiting_algorithmic_answer) {
		// 		const awaiting_algorithmic_answer = await Apollo.query('GET_POLLING_RESULT', { room_id: this.game.room.id, question_id: question.id, calculate: true })
		// 		this.$store.commit('EDIT_PROGRESS_QUESTION', { question, awaiting_algorithmic_answer })
		// 	}
		// },
		async send_answer(answer) {
			if (this.checked_answer) {
				if (!this.loading) {
					this.loading = true
					try {
						await this.$store.dispatch('send_answer', { question: this.selected_question, answer })
						if (this.selected_question.type !== 'Solo') {
							this.show_infobox({ slug: 'majority_awaiting_vote' })
							// await this.get_algorithmic_result(this.selected_question)
						}
					} catch (error) {
						console.log(error)
					}
					this.loading = false
				}
			}
		}
	},
	// created() {
	// 	if (this.selected_question) this.get_algorithmic_result(this.selected_question)
	// },
	components: { UiText, UiButton, MainTitle }
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'


#app.desktop, #app.tablet-horizontal
	.decision-answers
		.outcome
			transition 0.25s easeOutQuart
			&.outcome-enter, &.outcome-leave-to
				transform translateX(110%)
			.outcome-main
				.box
					&:before
						background-color var(--color-decisions-outcome-majority-choice-bg)
					&.losing:before
						background linear-gradient(to bottom, var(--color-decisions-outcome-majority-choice-bg) 0%, var(--color-decisions-outcome-bg) 10%, var(--color-decisions-outcome-bg) 100%)

#app.mobile, #app.tablet-vertical
	.decision-answers
		padding 0 24px
		.outcome
			min-height 200px
			.outcome-main
				padding 0 24px
				.box:before
					left -24px
					right -24px
			.waiting
				padding 0 24px
	

.decision-answers
	// min-height 100%
	// background-color darken(blue_darker, 15%)
	padding 0 32px
	background-color transparent

	.answers
		.answer
			padding 16px
			width 100%
			margin 8px 0
			flex flex-start flex-start
			border-radius 4px
			background-color var(--color-decisions-answer-bg)
			color var(--color-decisions-answer-text)
			cursor pointer
			// &:hover
			// 	.checkbox
			// 		background-color lighten(blue, 5%)
			&.checked
				background-color var(--color-decisions-answer-bg-checked)
				.checkbox
					background var(--color-decisions-answer-checkbox-bg-checked)
					color var(--color-decisions-answer-checkbox-text-checked)
			&.unchecked
				background-color var(--color-decisions-answer-bg-unchecked)
			.text
				width calc(100% - 48px)
				>>> .ui-text p:last-child
					margin 0
			.checkbox
				position absolute
				right 8px
				top 8px
				bottom 8px
				width 48px
				border-top-right-radius 4px
				border-bottom-right-radius 4px
				background var(--color-decisions-answer-checkbox-bg)
				color var(--color-decisions-answer-bg)
				flex center center
				>>> .ui-icon
					position absolute
					&.check-circle
						transform translate(2px, -2px)
	
	.buttons
		height 80px
		flex flex-end center
		margin 0 0 16px 0
		>>> .ui-button
			&.inactive
				opacity 0.25
			.button
				border-radius 4px
				background-color var(--color-decisions-answer-button-bg)
				font-size 2rem
				color var(--color-decisions-answer-button-text)
				font-weight 700
				text-transform uppercase
				font-family 'Josefin Sans'
				padding-top 6px
				.loader.loading
					absolute 2px
					border-radius 4px
	
	.outcome
		position absolute
		top 0
		right 0
		bottom 0
		width 100%
		background-color var(--color-decisions-outcome-bg)
		.outcome-main
			position absolute
			left 0
			top 0
			right 0
			height auto !important
			width 100%
			padding 0 16px 0 32px
			background-color var(--color-decisions-outcome-bg)
			// &.algorithmic
			// 	height 100% !important
			// 	> div, .box
			// 		height 100% !important
			.users
				position absolute
				right 0
				bottom 0
				height 40px
				max-width 50%
				flex flex-end flex-end
				.user
					float left
					width 24px
					height 40px
					margin 0 0 0 4px
					background bottom center no-repeat
					background-size auto 24px
					color var(--color-decisions-character-empty)
					// background-image url('../../../assets/img/progress/user-empty.svg')
					&.c-full
						color var(--color-decisions-character-full)
						.user-empty, .user-crown
							display none
						.user-full
							display block
					&.b-crown
						color var(--color-decisions-character-tie)
						.user-empty, .user-full
							display none
						.user-crown
							display block
							transform scale(1.25)
							transform-origin bottom center
					.user-full, .user-crown
						display none
					.user-empty
						display block
			.box
				// width calc(100% - 16px)
				width 100%
				padding 16px 0
				&:before
					content ''
					absolute 0
					left -32px
					right -32px
					background-color var(--color-decisions-outcome-majority-choice-bg)
				&.losing
					padding-top 80px
					&:before
						top 0px
						// bottom -32px
						// background darken(blue_darker, 15%)
						// background linear-gradient(to bottom, blue_dark 0%, darken(blue_darker, 15%) 10%, darken(blue_darker, 15%) 100%)
						background linear-gradient(to bottom, var(--color-decisions-outcome-majority-choice-bg) 0%, var(--color-decisions-outcome-bg) 10%, var(--color-decisions-outcome-bg) 100%)
				.box-title
					margin 0 0 16px 0
					border-bottom 2px solid var(--color-decisions-title-color)
					font-size 2.4rem
					color var(--color-decisions-title-color)
					text-transform uppercase
					.users
						bottom 8px
				.box-content
					.units
						margin 32px 0 16px 0
						font-family 'Josefin Sans'
						font-size 3.2rem
						text-transform uppercase
						.small
							font-size 1.6rem
					.option-answer
						border-bottom 2px solid var(--color-decisions-title-color)
						padding 24px 0
						&:last-child
							border none
					.text
						margin 0 0 16px 0
						&:last-child
							margin 0
						>>> .ui-text
							color var(--color-decisions-outcome-text)
							p:last-child
								margin-bottom 0
					.result-stats
						list-style disc
						margin-left 20px
						text-transform uppercase
						color var(--color-decisions-outcome-text)
						font-size 1.6rem
						.own
							color var(--color-decisions-title-color)
						.gold
							color var(--color-decisions-tie)
	
	.waiting
		position absolute
		top 50%
		left 0
		right 0
		text-align center
		color var(--color-decisions-outcome-text)
		transform translateY(-50%)
		>>> h1
			margin 0 0 8px 0
			color var(--color-decisions-title-color)
	
	.paused-overlay
		absolute 0
		flex center center column
		font-family 'Josefin Sans'
		font-size 2rem
		line-height 1.4em
		text-align center
		text-transform uppercase
		&:before
			content ''
			absolute 0
			background-color var(--color-bg)
			opacity 0.5


</style>
