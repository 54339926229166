import { render, staticRenderFns } from "./decision-answers.vue?vue&type=template&id=52b2c856&scoped=true&"
import script from "./decision-answers.vue?vue&type=script&lang=js&"
export * from "./decision-answers.vue?vue&type=script&lang=js&"
import style0 from "./decision-answers.vue?vue&type=style&index=0&id=52b2c856&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52b2c856",
  null
  
)

export default component.exports