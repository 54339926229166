<template>
	<div class="decision-question">

		<div v-if="selected_question" class="content">
			<div class="side-question">
				<div class="mark">?</div>
				<div class="text">
					<ui-text :text="selected_question.question" />
				</div>
			</div>
			<div v-if="selected_question.type === 'Decider'" class="roles">
				<div class="deciders">
					<div class="roles-title">Decider <span class="nb">{{ deciders.length }}</span></div>
					<div class="roles-content" v-show="participants_expanded">
						<div v-for="(player, index) in deciders" :key="index" class="role">
							<div class="name"><span v-if="player.name">{{ player.name }}</span><span v-else>???</span></div>
							<div class="player-name">{{ player.student_name }}</div>
							<!-- <div class="role-name">{{ player.role.name }}</div> -->
						</div>
					</div>
				</div>
				<div class="contributors">
					<div class="roles-title">Contributors <span class="nb">{{ contributors.length }}</span></div>
					<div class="roles-content" v-show="participants_expanded">
						<div v-for="(player, index) in contributors" :key="index" class="role">
							<div class="name"><span v-if="player.name">{{ player.name }}</span><span v-else>???</span></div>
							<div class="player-name">{{ player.student_name }}</div>
							<!-- <div class="role-name">{{ player.role.name }}</div> -->
						</div>
					</div>
				</div>
			</div>
			<div v-if="selected_question.type === 'Majority'" class="roles">
				<div class="participants">
					<div class="roles-title">Participants <span class="nb">{{ deciders.length }}</span></div>
					<div class="roles-content" v-show="participants_expanded">
						<div v-for="(player, index) in deciders" :key="index" class="role">
							<div class="name"><span v-if="player.name">{{ player.name }}</span><span v-else>???</span></div>
							<div class="player-name">{{ player.student_name }}</div>
							<!-- <div class="role-name">{{ player.role.name }}</div> -->
						</div>
					</div>
				</div>
			</div>
			<div v-if="selected_question.type === 'Decider' || selected_question.type === 'Majority'" class="buttons buttons-participants">
				<div class="button-expand" :class="{ collapsed: !participants_expanded }" v-hammer:tap="() => participants_expanded = !participants_expanded">
					<span v-if="participants_expanded">Minimize participants</span>
					<span v-else>Expand participants</span>
				</div>
			</div>
			<div class="side-question-description" :class="{ collapsed: (app.device === 'mobile' || app.device === 'tablet-vertical') && !description_expanded }">
				<ui-text :text="selected_question.description" />
			</div>
			<div v-if="(app.device === 'mobile' || app.device === 'tablet-vertical') && selected_question.description.length > 100" class="buttons">
				<div class="button-expand" :class="{ collapsed: !description_expanded }" v-hammer:tap="() => description_expanded = !description_expanded">
					<span v-if="description_expanded">Minimize description</span>
					<span v-else>Expand description</span>
				</div>
			</div>
			<div v-if="selected_question_config && selected_question_config.question_id && selected_question_config.units && selected_question_config.outcome" class="config-array">
				<table class="table">
					<tr class="row head">
						<td colspan="2"></td>
						<td class="top-title" colspan="6">Your decision</td>
					</tr>
					<tr class="row head">
						<th class="cell head"></th>
						<th class="cell head"></th>
						<th v-for="(answer, index) in selected_question_config.units.answers" :key="'a' + index" class="cell head">{{ answer.label }}</th>
					</tr>
					<tr v-for="(row, i) in selected_question_config.outcome" :key="i" class="row">
						<td v-if="i === 0" rowspan="12" class="left-title" width="110">Global total carbon reduction</td>
						<td key="range" class="cell head left">{{ row.range_min }}-{{ row.range_max }}</td>
						<td v-for="(value, col) in row.score" :key="'td' + col" class="cell" :class="get_cell_class(col, value)">{{ value }}</td>
					</tr>
				</table>
				<div class="legends">
					<div>34 = Average Year</div>
					<div>50 = Global Financial Crisis</div>
					<div>100 = Great Depression</div>
				</div>
			</div>
		</div>
		
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import UiText from '@/components/ui/ui-text'

export default {
	name: 'decision-question',
	data() {
		return {
			participants_expanded: false,
			description_expanded: false
		}
	},
	props: {
		selected_question: { default: null }
	},
	computed: {
		...mapGetters([ 'app', 'game', 'logged_user', 'selected_question_config' ]),
		deciders() {
			let deciders = []
			if (this.selected_question && this.selected_question.type !== 'Solo') {
				let roles = this.game.roles.filter((role) => {
					return this.selected_question.deciders.includes(parseInt(role.id))
				})
				if (roles.length > 0) {
					roles.forEach((role) => {
						let player = this.game.players.find((p) => {
							return p.role && parseInt(p.role.id) === parseInt(role.id)
						})
						if (!player) player = {}
						deciders.push({ ...player, role })
					})
				}
			}
			return deciders
		},
		contributors() {
			let contributors = []
			if (this.selected_question && this.selected_question.type !== 'Solo') {
				let roles = this.game.roles.filter((role) => {
					return this.selected_question.contributors.includes(parseInt(role.id))
				})
				if (roles.length > 0) {
					roles.forEach((role) => {
						let player = this.game.players.find((p) => {
							return p.role && parseInt(p.role.id) === parseInt(role.id)
						})
						if (!player) player = {}
						contributors.push({ ...player, role })
					})
				}
			}
			return contributors
		},
		// selected_question_config() {
		// 	if (this.game.scenario_config && this.game.scenario_config[0] && this.game.scenario_config[0].value && this.selected_question && this.selected_question.type === 'Algorithmic') {
		// 		const value = JSON.parse(this.game.scenario_config[0].value)
		// 		return {
		// 			question_id: this.selected_question.id,
		// 			units: value.decision.find(c => c.role_id.includes(parseInt(this.logged_user.role.id))),
		// 			outcome: value.outcome
		// 		}
		// 	}
		// 	return { question_id: null, units: [], outcome: [] }
		// }
	},
	methods: {
		get_cell_class(col, value) {
			if (value <= 34) return 'lighter'
			if (value <= 46) return 'light'
			if (value <= 79) return 'medium'
			return 'strong'
		}
	},
	components: { UiText }
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'


#app.desktop, #app.tablet-horizontal
	.decision-question
		.side-question-description
			background transparent
			padding 0 32px

#app.mobile, #app.tablet-vertical
	.decision-question
		padding 0 24px
		.side-question-description, .buttons
			margin 0 -24px
			padding-right 24px
			padding-left 24px

#app.mobile
	.decision-question .roles .participants .roles-content .role
		width 100%
		padding 0
		margin 0 0 8px 0

.decision-question
	// background-color blue_dark
	margin 0 0 24px 0
	padding 0 32px

	.side-question
		display block
		border 2px solid var(--color-primary)
		padding 2px
		min-height 70px
		margin 0 0 16px 0
		.mark
			position absolute
			left 2px
			top 2px
			bottom 2px
			flex center center
			width 64px
			padding-top 8px
			background-color var(--color-decisions-mark-bg)
			color var(--color-decisions-mark-text)
			font-size 4rem
			font-weight 700
			font-family 'Josefin Sans'
		.text
			padding 8px
			flex flex-start center
			min-height 64px
			margin-left 72px
			color var(--color-decisions-question-text)
			text-transform uppercase
			line-height 20px
			>>> .ui-text
				p:last-child
					margin-bottom 0
	
	.roles
		flex flex-start flex-start
		// margin 0 0 32px 0
		.deciders
			width 33.3333%
		.contributors
			width calc(66.6666% - 24px)
			margin-left 24px
		.participants
			width 100%
		.deciders, .contributors, .participants
			.roles-title
				margin 0 0 16px 0
				border-bottom 2px solid var(--color-primary)
				color var(--color-primary)
				line-height 24px
				text-transform uppercase
				.nb
					position absolute
					right 4px
					top 0
			.roles-content
				color var(--color-decisions-question-description-text)
				flex flex-start flex-start
				flex-wrap wrap
				.role
					width 50%
					// margin 0 0 16px 0
					padding 8px
					.name
						color var(--color-primary)
						text-transform uppercase
					.role-name
						margin-left 24px
		
	.side-question-description
		color var(--color-decisions-question-description-text)
		line-height 22px
		padding 24px
		margin 0 -32px
		background-color var(--color-decisions-question-description-mobile-bg)
		&.collapsed
			height 100px
			overflow hidden
			&:after
				content ''
				position absolute
				left 0
				right 0
				bottom 0
				height 100px
				background linear-gradient(to top, var(--color-decisions-question-description-mobile-bg) 10%, transparent 50%)
		
	.buttons
		text-align right
		&.buttons-participants
			margin-bottom 20px
			background-color transparent
			.button-expand
				background-color var(--color-decisions-question-description-mobile-expand-button-bg)
				color var(--color-decisions-question-description-mobile-expand-button-text)
				&:after
					border-color var(--color-decisions-question-description-mobile-expand-button-text) transparent transparent transparent
				// &:hover
				// 	background-color lighten(blue, 5%)
		.button-expand
			flex center center
			height 40px
			padding 0 16px
			line-height @height
			background-color var(--color-decisions-question-description-mobile-expand-button-bg)
			color var(--color-decisions-question-description-mobile-expand-button-text)
			border-radius 40px
			cursor pointer
			&.collapsed:after
				transform rotate(0)
			&:after
				content ''
				margin 0 0 0 4px
				width 0
				height 0
				border-style solid
				border-width 12px 8px 0 8px
				border-color var(--color-decisions-question-description-mobile-expand-button-text) transparent transparent transparent
				transform rotate(180deg)
				transition transform 0.25s easeOutQuart


	// .answers
	// 	.answer
	// 		padding 16px
	// 		width 100%
	// 		margin 8px
	// 		flex flex-start flex-start
	// 		border-radius 4px
	// 		background-color red
	// 		color blue_dark
	// 		cursor pointer
	// 		&:hover
	// 			.checkbox
	// 				background-color lighten(blue, 5%)
	// 		&.checked
	// 			.checkbox
	// 				background #82CBEE url(../../../assets/img/check-circle.svg) center center no-repeat
	// 				background-size 32px auto
	// 		.text
	// 			width calc(100% - 48px)
	// 			>>> .ui-text p:last-child
	// 				margin 0
	// 		.checkbox
	// 			position absolute
	// 			right 8px
	// 			top 8px
	// 			bottom 8px
	// 			width 48px
	// 			border-top-right-radius 4px
	// 			border-bottom-right-radius 4px
	// 			background blue url('../../../assets/img/circle.svg') center center no-repeat
	// 			background-size 26px auto
	
	.buttons
		height 72px
		margin 0 -32px
		padding 16px 32px
		background-color var(--color-decisions-question-description-mobile-bg)
		flex flex-end center
		>>> .ui-button
			&.inactive
				opacity 0.25
				.button
					color alpha(blue_dark, 50%)
					text-decoration none
			.button
				border-radius 4px
				background-color var(--color-decisions-question-description-mobile-expand-button-bg)
				font-size 2rem
				color var(--color-decisions-question-description-mobile-expand-button-text)
				font-weight 700
				text-transform uppercase
				font-family 'Josefin Sans'
				padding-top 6px
				.loader.loading
					absolute 2px
					background-color blue
					border-radius 4px
	
	.config-array
		.table
			width 100%
			border-collapse 8px
			th, td
				padding 6px
				text-align center
				&.head
					background-color transparent
					font-weight normal
					&.left
						width 90px
				&.lighter
					background-color var(--color-algo-array-units-lighter-bg)
					color var(--color-algo-array-units-lighter-text)
				&.light
					background-color var(--color-algo-array-units-light-bg)
					color var(--color-algo-array-units-light-text)
				&.medium
					background-color var(--color-algo-array-units-medium-bg)
					color var(--color-algo-array-units-medium-text)
				&.strong
					background-color var(--color-algo-array-units-dark-bg)
					color var(--color-algo-array-units-dark-text)
			.top-title
				border-bottom 1px solid #9F9F9F
				padding 0 0 4px 0
				text-transform uppercase
				font-weight 700
				text-align center
			.left-title
				border-right 1px solid #9F9F9F
				padding 0 10px 0 0
				vertical-align top
				text-transform uppercase
				font-weight 700
				text-align right
		.legends
			margin 16px 0 0 205px
			flex center center
			flex-wrap wrap
			> div
				margin 2px 8px
				font-size 1.2rem
				white-space nowrap
				&:first-child
					margin-left 0
				&:last-child
					margin-right 0
	

	// .outcome
	// 	position absolute
	// 	top 0
	// 	right 0
	// 	bottom 0
	// 	width 100%
	// 	background-color lighten(blue_dark, 3%)
	// 	border-left 2px solid blue_lighter
	// 	transition 0.25s easeOutQuart
	// 	&.outcome-enter, &.outcome-leave-to
	// 		transform translateX(110%)
	// 	.outcome-main
	// 		position absolute
	// 		left 0
	// 		top 0
	// 		right 0
	// 		width 100%
	// 		height 100%
	// 		padding 0 16px 0 32px
	// 		background darken(blue_darker, 15%)
	// 		.users
	// 			position absolute
	// 			right 0
	// 			bottom 0
	// 			height 40px
	// 			max-width 50%
	// 			flex flex-end flex-end
	// 			.user
	// 				float left
	// 				width 24px
	// 				height 40px
	// 				margin 0 0 0 4px
	// 				background bottom center no-repeat
	// 				background-size auto 24px 
	// 				background-image url('../../../assets/img/progress/user-empty.svg')
	// 				&.c-full
	// 					background-image url('../../../assets/img/progress/user-full.svg')
	// 				&.b-crown
	// 					background-size auto 32px 
	// 					background-image url('../../../assets/img/progress/user-crown.svg')
	// 		.box
	// 			width calc(100% - 16px)
	// 			// margin 0 0 32px 0
	// 			padding 16px 0
	// 			&:before
	// 				content ''
	// 				absolute 0
	// 				left -32px
	// 				right -32px
	// 				background-color lighten(blue_dark, 3%)
	// 			&.losing
	// 				padding-top 80px
	// 				&:before
	// 					top 0px
	// 					bottom -32px
	// 					background darken(blue_darker, 15%)
	// 					background linear-gradient(to bottom, #003348 0%, darken(blue_darker, 15%) 10%, darken(blue_darker, 15%) 100%)
	// 			.box-title
	// 				margin 0 0 16px 0
	// 				border-bottom 2px solid blue_lighter
	// 				font-size 2.4rem
	// 				color blue_lighter
	// 				text-transform uppercase
	// 				.users
	// 					bottom 8px
	// 			.box-content
	// 				.option-answer
	// 					border-bottom 2px solid blue_lighter
	// 					padding 24px 0
	// 					&:last-child
	// 						border none
	// 				.text
	// 					margin 0 0 16px 0
	// 					&:last-child
	// 						margin 0
	// 					>>> .ui-text
	// 						color gray_light
	// 						p:last-child
	// 							margin-bottom 0
	// 				.result-stats
	// 					list-style disc
	// 					margin-left 20px
	// 					text-transform uppercase
	// 					color gray_light
	// 					font-size 1.6rem
	// 					.own
	// 						color blue_lighter
	// 					.gold
	// 						color #F2C555
	.waiting
		position absolute
		top 50%
		left 0
		right 0
		text-align center
		color gray_light
		transform translateY(-50%)
		>>> h1
			margin 0 0 8px 0
			color blue_lighter

</style>
