<template>
	<div class="view view-decisions">

		<div class="content">

			<div class="part left" :class="{ 'has-selected-question': selected_question }">
				<main-title icon="menu/menu-progress" text="Decisions" :closable="timeline_visible" />

				<div v-if="(app.device === 'mobile' || app.device === 'tablet-vertical') && selected_question" class="back-button" v-hammer:tap="back">Back</div>

				<div class="scroll" v-scrollbar>
					<div>
						<decision-question :selected_question="selected_question" />
						<decision-answers v-if="(app.device === 'mobile' || app.device === 'tablet-vertical')" :selected_question="selected_question" :selected_answer="selected_answer" :correct_answer="correct_answer" />
					</div>
				</div>
				<decision-timeline v-if="timeline_visible" :selected_question="selected_question" />
			</div>

			<div v-if="app.device !== 'mobile' && app.device !== 'tablet-vertical'" class="part right">
				<div class="scroll" v-scrollbar>
					<div>
						<decision-answers :selected_question="selected_question" :selected_answer="selected_answer" :correct_answer="correct_answer" />
					</div>
				</div>
			</div>

		</div>


	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import MainTitle from '@/components/game/main-title'
import DecisionQuestion from '@/components/game/decisions/decision-question'
import DecisionTimeline from '@/components/game/decisions/decision-timeline'
import DecisionAnswers from '@/components/game/decisions/decision-answers'

export default {
	name: 'view-decisions',
	computed: {
		...mapGetters([ 'app', 'game', 'progress', 'current_question', 'logged_user' ]),
		timeline_visible() {
			return (this.app.device !== 'mobile' && this.app.device !== 'tablet-vertical') || (!this.selected_question)
		},
		is_dev() {
			return false
			return process.env.NODE_ENV === 'development'
		},
		selected_question() {
			// if (this.is_dev) return this.progress.selected_question
			if (this.progress.selected_question) {
				if ((this.is_dev) || (this.progress.selected_question && this.game.room.timer <= this.game.room.timer_total - this.progress.selected_question.timer)) {
					return this.progress.selected_question
				}
			}
			return null
		},
		selected_answer() {
			if (this.selected_question) {
				if (this.is_dev) return this.selected_question.answer[0]
				return this.selected_question.answer.find((answer) => { return this.progress.selected_answers.includes(parseInt(answer.id)) })
			}
			return null
		},
		correct_answer() {
			if (this.selected_question) {
				if (this.is_dev) return this.selected_question.answer[0]
				if (this.selected_question.type === 'Solo') {
					return this.selected_question.answer.find((a) => { return this.progress.selected_answers.includes(parseInt(a.id)) })
				}
				if (this.selected_question.majority_answer) {
					let a = this.selected_question.majority_answer
					if (a/* && a.status*/) {
						const result_answer = this.selected_question.answer.find((answer) => { return parseInt(answer.id) === parseInt(a.result) })
						// return result_answer
						return { ...a, result_answer }
					}
				}
			}
			return null
		},
	},
	methods: {
		back() {
			this.$store.dispatch('set_progress', { selected_question: null })
		}
	},
	created() {
		if (this.current_question) this.$store.dispatch('set_progress', { selected_question: this.current_question })
	},
	components: { MainTitle, DecisionQuestion, DecisionTimeline, DecisionAnswers }
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'


#app.desktop, #app.tablet-horizontal
	.view-decisions
		.content .part
			&.left
				width 55%
				border-right 2px solid transparent
				&.has-selected-question
					border-right 2px solid var(--color-primary)
			&.right
				width 45%
				>>> .decision-answers
					min-height 100%

#app.mobile, #app.tablet-vertical
	.view-decisions
		.content .part.left > .scroll
			height calc(100% - 96px)

.view-decisions
	// background-color blue_dark
	
	.content
		height 100%
		width 100%
		flex center center 
		
		.part
			height 100%
			&.left
				width 100%
				height 100%
				// padding 0 0 0 32px
				> .main-title
					position absolute
					left 32px
					top 16px
					width calc(100% - 64px)
				> .back-button
					position absolute
					right 24px
					top 24px
					height 40px
					padding 0 16px
					flex center center
					border-radius 40px
					background-color var(--color-decisions-back-button-bg)
					color var(--color-decisions-back-button-text)
					text-align center
					line-height @height
					font-weight 700
					text-transform uppercase
					&:before
						content ''
						margin 0 8px 0 0
						width 0
						height 0
						border-style solid
						border-width 8px 12px 8px 0
						border-color transparent var(--color-decisions-back-button-text) transparent transparent

				> .decision-timeline
					position absolute
					left 32px
					bottom 0
					width calc(100% - 64px)
				> .scroll
					margin 96px 0 0 0
					height 'calc(100% - %s)' % (80px + 96px)
					// padding-right 32px
			&.right
				width 45%
				>>> .main-title
					margin-top 0
			.scroll
				> div
					height 100%


	
</style>
