<template>
	<div class="decision-timeline">
		<div class="line"></div>

		<hooper class="questions" :settings="slider_settings" ref="carousel" @updated="slider_updated">
			<slide
				v-for="(question, index) in visible_questions"
				class="question"
				:key="question.id"
				:class="{
					current: selected_question && question.id == selected_question.id,
					selected: selected_question && selected_question.id == question.id
				}"
				v-hammer:tap="() => set_selected_question(question)"
			>
				<div class="dot"></div>
				<div v-if="selected_question && question.id == selected_question.id" class="text">CURRENT DECISION</div>
				<div v-else class="text">
					{{ index + 1 }}<span v-if="index + 1 === 1">st</span><span v-if="index + 1 === 2">nd</span><span v-if="index + 1 === 3">rd</span><span v-if="index + 1 > 3">th</span>
					<br v-if="app.device !== 'mobile' && app.device !== 'tablet-vertical'"> DECISION
				</div>
				<ui-icon v-if="app.device === 'mobile' || app.device === 'tablet-vertical'" name="right-arrow-blue-light" :size="24" />
			</slide>
		</hooper>

		<div v-if="prev_visible" class="slider-arrow prev" v-hammer:tap="slide_prev"><</div>
		<div v-if="next_visible" class="slider-arrow next" v-hammer:tap="slide_next">></div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Hooper, Slide } from 'hooper'
import 'hooper/dist/hooper.css'

export default {
	name: 'decision-timeline',
	data() {
		return {
			slider_buttons_visible: false,
			slider_settings: {
				trimWhiteSpace: true,
				vertical: true,
				itemsToShow: 8,
				breakpoints: {
					900: {
						vertical: false,
						itemsToShow: 4
					},
					1000: {
						vertical: false,
						itemsToShow: 5
					},
					1150: {
						vertical: false,
						itemsToShow: 6
					},
					1300: {
						vertical: false,
						itemsToShow: 7
					},
				}
			}
		}
	},
	props: {
		selected_question: { default: null }
	},
	computed: {
		...mapGetters([ 'app', 'game', 'progress', 'questions' ]),
		visible_questions() {
			return this.questions.filter((question) => ( this.is_dev || (this.game.room.timer <= this.game.room.timer_total - question.timer && !question.direct_message) ) )
		},
		is_dev() {
			return false
			return process.env.NODE_ENV === 'development'
		},
		prev_visible() {
			if (this.slider_buttons_visible) {
				return this.$refs.carousel.currentSlide > 0
			}
			return false
		},
		next_visible() {
			if (this.slider_buttons_visible) {
				return this.$refs.carousel.currentSlide + this.$refs.carousel.config.itemsToShow < this.visible_questions.length
			}
			return false
		},
	},
	methods: {
		slider_updated() {
			if (this.visible_questions.length > this.$refs.carousel.config.itemsToShow) {
				this.slider_buttons_visible = true
			} else
				this.slider_buttons_visible = false
		},
		set_selected_question(question) {
			if (this.progress.selected_question && question.id === this.progress.selected_question.id) {
				this.$store.dispatch('set_progress', { selected_question: null })
			} else {
				this.$store.dispatch('set_progress', { selected_question: question })
				if (this.selected_question && this.selected_question.id === question.id) {
					this.show_infobox({ question })
				}
			}
		},
		slide_prev() {
			this.$refs.carousel.slidePrev()
		},
		slide_next() {
			this.$refs.carousel.slideNext()
		},
	},
	// mounted() {
	// 	if (this.questions.length > 0) {
	// 		console.log(this.selected_question)
	// 		if (this.selected_question) this.set_selected_question(this.selected_question)
	// 	}
	// },
	components: { Hooper, Slide }
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'


#app.mobile, #app.tablet-vertical
	.decision-timeline
		absolute 0
		top 96px
		height calc(100% - 120px)
		width 100%
		.line
			left 48px
			top 0
			bottom 0
			width 2px
			height 100%
		.slider-arrow
			display none
		.questions
			flex-direction column
			.question
				width 'calc(100% - %s)' % (48px + 32px)
				height 48px
				padding 0 0 0 24px
				margin 0 0 16px (48px + 2px)
				// background-color blue
				border-radius 0 6px 6px 0
				white-space nowrap
				text-align left
				line-height @height
				color var(--color-primary)
				&:before
					display none
				.dot
					right auto
					left -7px
					top calc(50% - 8px)
				>>> .ui-icon
					position absolute
					right 16px
					top calc(50% - 12px)

.decision-timeline
	width 100%
	height 80px
	background-color var(--color-bg)
	.line
		position absolute
		left 0
		top 13px
		right 0
		height 2px
		background-color var(--color-primary)
	.slider-arrow
		position absolute
		top calc(50% - 16px)
		height 32px
		width 32px
		flex center center
		background-color var(--color-primary)
		color var(--color-bg)
		border-radius 50%
		font-size 2.4rem
		cursor pointer
		&.prev
			left -24px
		&.next
			right -32px
		&:active
			transform translateY(1px)
	.questions
		width 100%
		height 100%
		flex flex-start flex-start
		outline none
		.question
			width 96px
			padding 32px 24px 0 0
			text-align right
			color var(--color-text)
			line-height 16px
			cursor pointer
			&.selected
				color var(--color-primary)
				&:before
					background-color var(--color-primary)
			&.current
				.dot
					background-color var(--color-primary)
					transform scale(1.5)
			&:before
				content ''
				position absolute
				right 14px
				top 8px
				width 2px
				height calc(100% - 8px)
				background-color var(--color-primary)
			.dot
				position absolute
				right 6px
				top 7px
				width 16px
				height 16px
				background-color var(--color-bg)
				border-radius 50%
				box-shadow 0 0 0 2px var(--color-primary)


</style>
